import '@ckeditor/ckeditor5-build-classic/build/ckeditor'

function setupEditor() {
  const editorElement = document.querySelector( '#act_type_note_template' )
  console.log('Trying CKEditor with element: ', editorElement)
  if (editorElement) {
    ClassicEditor
      .create( editorElement )
      .then( editor => {
        console.log( editor )
      })
      .catch( error => {
        console.error( error )
      })
  }
}

document.addEventListener("turbolinks:load", function() {
  setupEditor()
})
