import { Controller } from "stimulus"
import 'jquery'

export default class extends Controller {
  
  static targets = [ "modal", "link", "modalContent", "spinner" ]

  connect() {
    $(this.modalTarget).on("ajax:success", (event) => {
      const [data, status, xhr ] = event.detail
      if (xhr.status == 201) {
        this.hideSpinner()
        this.hideModal()
        this.reload()
      } else {
        this.modalContentTarget.innerHTML = xhr.response
      }
    })
  }

  openModal(event) {
    event.preventDefault()
    this.showModal()
    this.showSpinner()
    this.setModelContent('')
    fetch(event.currentTarget.dataset.dialogUrl)
      .then(response => response.text())
      .then(html => {
        this.hideSpinner()
        this.setModelContent(html)
      })
  }

  reload() {
    location.reload()
  }

  showModal() {
    $(this.modalTarget).modal('show')
  }

  hideModal() {
    $(this.modalTarget).modal('hide')
  }

  showSpinner() {
    $(this.spinnerTarget).show()
  }

  hideSpinner() {
    $(this.spinnerTarget).hide()
  }

  setModelContent(html) {
    this.modalContentTarget.innerHTML = html
  }
}
