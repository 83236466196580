import "core-js/stable"
import "regenerator-runtime/runtime"
require('@rails/ujs').start()
require('turbolinks').start()
import 'bootstrap'
import './layout/layout-2'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import './ckeditor'

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
